/* Main theme #C32126 */

.App {
  /*text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.svg-spin{
  animation: spin 3s linear infinite alternate;
  margin-bottom: 10px;
}

.loading-container{
  display: grid;
  position: fixed;
  justify-content: center;
  justify-items: center;
  align-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20,.3), rgba(0, 0, 0, .3));
  background: -webkit-radial-gradient(rgba(20, 20, 20,.3), rgba(0, 0, 0,.3));
  z-index: 999999;
}

.logout-text {
  background: #140304;
  background: linear-gradient(to right, #140304 0%, #C32126 67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3em;
  font-weight: bold;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #f0f4ff !important;
}

.highlight {
  color: #c41d7f;
  font-weight: 500;
}

.article-title {
  font-size: 1.5rem;
}
/****** 404 PAGE ********/
.cls-1{fill:#d6b49a;}.cls-1,.cls-10,.cls-11,.cls-13,.cls-14,.cls-15,.cls-17,.cls-2,.cls-4,.cls-5,.cls-6{stroke:#000;}.cls-1,.cls-11,.cls-13,.cls-14,.cls-16,.cls-8{stroke-linecap:round;stroke-linejoin:round;}.cls-1,.cls-10,.cls-11,.cls-12,.cls-13,.cls-14,.cls-15,.cls-16,.cls-17,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-8,.cls-9{stroke-width:3px;}.cls-2{fill:#020202;}.cls-10,.cls-12,.cls-15,.cls-17,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7,.cls-9{stroke-miterlimit:10;}.cls-3{fill:#818181;}.cls-12,.cls-16,.cls-3,.cls-7,.cls-8,.cls-9{stroke:#191818;}.cls-4{fill:#dcdbda;}.cls-5{fill:#4ea7f1;}.cls-14,.cls-6{fill:#f8f3ed;}.cls-16,.cls-7{fill:#333;}.cls-13,.cls-8{fill:none;}.cls-9{fill:#f8f59c;}.cls-10,.cls-11{fill:#f3d2c9;}.cls-15{fill:#8bb174;}.cls-17{fill:#da4e22;}
